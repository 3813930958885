import React from "react";
import {Route, Switch, Redirect, withRouter} from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/login/Login";

import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

// import Settings from "./containers/Settings.js";
import Add from "./containers/add/AddNew";
import AddManagers from "./containers/add/AddManager"
import ChangePassword from "./containers/login/ChangePassword";
import UpdateDetails from "./containers/login/UpdateDetails"
import Tasks from "./containers/Tasks"
import NewForm from "./containers/form/FormContainer"
import Applicants from "./containers/applicants/Applicants"
import Offer from "./containers/offer/Offer"
import Myob from "./containers/exports/Myob"
import ChristmasCasuals from "./containers/christmas/ChristmasCasuals"
import PrivacyPolicy from "./containers/policy/PrivacyPolicy"
import withAuth from "./components/WithAuth"
import AdminPanel from "./containers/admin/AdminPanel";
import Feed from "./containers/feed/Feed"
import ShippingNew from "./containers/shipping/ShippingTestNew";
import Shipping from "./containers/shipping/ShippingTest";
import Orders from "./containers/stores/StoreOrderView";
import Collect from "./containers/shipping/CollectVerification"
import CollectNew from "./containers/shipping/CollectVerificationNew"
import Reporting from "./containers/reporting/Reporting";
import ReportingNew from "./containers/reporting/ReportingNew";
import ShippingReport from "./containers/reporting/shipping/shippingReport";
import ShippingReportNew from "./containers/reporting/shipping/shippingReportNew";

import TestPrinter from "./containers/TestPrinter"
import { connect } from "react-redux";

// New feature flag stores
const newFeatureStores = [''];

// Store-specific report URLs
const storeReportUrls = {
    // '407': 'https://lookerstudio.google.com/embed/reporting/4c13e640-5bbc-40e1-9a91-7afe4632363c/page/p_5dooay9agd',
    '640': 'https://lookerstudio.google.com/embed/reporting/9e04b599-0b98-4ab2-bdce-80c569ebbe46/page/p_4kharul80c',
    'default': ''
};

const createComponentSwitcher = (NewComponent, LegacyComponent) => {
    const ComponentWrapper = ({ user, ...otherProps }) => {
        const storeId = user?.store || '';
        const useNewComponent = newFeatureStores.includes(storeId);

        // Get the store-specific report URL or default if not found
        const reportUrl = storeReportUrls[storeId] || storeReportUrls.default;

        const Component = useNewComponent ? NewComponent : LegacyComponent;
        return <Component reportUrl={reportUrl} {...otherProps} />;
    };

    return connect(
        state => ({ user: state.authState })
    )(ComponentWrapper);
};

const ConnectedShippingWrapper = createComponentSwitcher(ShippingNew, Shipping);
const ConnectedCollectWrapper = createComponentSwitcher(CollectNew, Collect);
const ConnectedReportingWrapper = createComponentSwitcher(ReportingNew, Reporting);
const ConnectedShippingReportWrapper = createComponentSwitcher(ShippingReportNew, ShippingReport);

/**
 * Routes take in a componenet that is wrapped in a HOC (withAuth), withAuth takes in three parameters (Component,state,allowedPermissions)
 * where state is state to be injected into the store.
 * Both state and allowedPermissions are optional
 * @param childProps
 * @returns {*}
 */
export default ({ childProps }) =>

    <Switch>

        <AuthenticatedRoute path="/" exact component={withAuth(Home)} props={childProps}/>

        <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />

        <Route path="/feed" exact component = {withAuth(Feed,"feedState")} />

        <Route path="/forgotten" exact component={ChangePassword} props={childProps} />

        <Route path="/update" exact component={withAuth(UpdateDetails)} />

        <Route path="/add" exact component={withAuth(Add,"storesState",["onboarding::add"])} />

        <Route path="/add/managers" exact component={withAuth(AddManagers,null,["onboarding::add::manager"])} />

        <Route path="/onboarding" exact component={withAuth(NewForm,"onboardingState")} />

        <Route path="/tasks" exact component = {withAuth(Tasks)} />

        <Route path="/ship" exact component={withAuth(ConnectedShippingWrapper, 'shippingState', ["shipping"], ['HR','STORE'])} />

        <Route path="/order" exact component = {withAuth(Orders,['checkoutState','catalogState'],["shipping"], ['STORE'])} />

        <Route path="/printer" exact component = {withAuth(TestPrinter,null,["shipping"], ['STORE'])} />

        <Route path="/collect" exact component={withAuth(ConnectedCollectWrapper, 'shippingState', ["shipping"], ['HR','STORE'])} />

        <Route path = "/privacy" exact component ={PrivacyPolicy} />

        {/* <Route path="/reporting" exact component={withAuth(ConnectedReportingWrapper, 'reportingState', ["reporting::full", "reporting::store"])} /> */}
        <Route path="/reporting" exact component={withAuth(ConnectedReportingWrapper, 'reportingState', ["shipping"], ['HR','STORE'])} />

        {/* <Route path="/reporting/shipping" exact component={withAuth(ConnectedShippingReportWrapper, 'reportingState', ["reporting::full", "reporting::store"])} /> */}
        <Route path="/reporting/shipping" exact component={withAuth(ConnectedShippingReportWrapper, 'reportingState', ["shipping"], ['HR','STORE'])} />

        <Route path = "/christmas" exact component = {ChristmasCasuals} />

        <Route path="/applicants" exact component = {withAuth(Applicants,"applicantsState",["onboarding::applicants"])} />

        <Route path="/offer" exact component = {withAuth(Offer,null)} />

        <Route path="/admin" exact component={withAuth(AdminPanel,['adminState','storesState'],["admin"],['HR','SUPERUSER'])} />

        <Route path="/myob" exact component={withAuth(Myob,null,["view:myob"])} />

        <Route component={NotFound} />
    </Switch>

;




